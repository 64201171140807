<template>
    <BaseSection>
        <template #title>
            <h1 v-if="error && error.statusCode === 404">{{ title }}</h1>
            <h1 v-else>An error occurred</h1>
        </template>
    </BaseSection>

    <template v-if="error.statusCode === 404">
        <p class="lead">
            {{ description }}
        </p>

        <p v-if="extraDescription">
            {{ extraDescription }}
        </p>

        <BaseSection>
            <QuicklinksList
                class="QuicklinksList--large"
                :links="[
                    { text: 'Search through our website', uri: '/search' },
                    { text: 'Go back to the homepage', uri: '/' },
                    { text: 'Find help on the contact page', uri: '/contact' },
                ]"
            />
        </BaseSection>
    </template>

    <p
        v-else-if="error && error.message"
        class="lead"
    >
        {{ error.message }}
    </p>
</template>

<script setup>
import {useSiteDataStore} from '~/store/siteDataStore';

const { activeSite } = useMultisite();
const { translations } = useSiteDataStore();

const localePath = useRoute().path?.split('/')?.filter(Boolean)?.[0];
let locale = localePath;

const title = ref('');
const description = ref('');
const extraDescription = ref('');

// Fix for Dutch locale, only supported locale that is more than 2 characters
if (locale === 'nl') {
    locale = 'nl-NL';
}

const category = 'site';

const getFallbackTranslation = (key) =>
    toValue(translations)?.[locale]?.[category]?.[key] ??
    toValue(translations)?.['en-US']?.[category]?.[key] ??
        key;

const props = defineProps({
    error: {
        type: Object,
        required: false,
        default: null
    }
});

if (props.error.statusCode === 404) {
    if (toValue(activeSite).locale === 'en-US') {
        // If the locale is set to English, it's possible that we're on a 404 page for a non-English site
        // For this case, we want to use the custom translation implementation
        title.value = getFallbackTranslation('404-title');
        description.value = getFallbackTranslation('404-description');
        extraDescription.value = getFallbackTranslation('404-extra-description');
    } else {
        // If the locale is not set to English, we can use the useTranslation() implementation
        const t = useTranslation('site');

        title.value = t('404-title');
        description.value = t('404-description');
        extraDescription.value = t('404-extra-description');
    }
} else {
    title.value = 'An error occurred';
    description.value = props.error.message;
}

const data = computed(() => {
    return {
        title: title.value,
        description: description.value
    };
});

const head = useSeo(data, data, undefined, activeSite);

useHead(head);
</script>
