
import {useSiteDataStore} from '~/store/siteDataStore';

const FB = 'en-US';

export const useTranslation = (category: string = 'site') => {
    const {translations} = useSiteDataStore();
    const {activeSite} = useMultisite();

    let locale = toValue(activeSite)?.locale;

    // Fix for Dutch locale, only supported locale that is more than 2 characters
    if (locale === 'nl') {
        locale = 'nl-NL';
    }

    return (key: string) =>
        toValue(translations)?.[locale]?.[category]?.[key] ??
        toValue(translations)?.[FB]?.[category]?.[key] ??
        key;

};
