<template>
    <NuxtLayout>
        <NuxtPage
            class="layout__main"
            :class="{
                'enable-transitions': enableTransitions,
            }"
        />
    </NuxtLayout>
</template>

<script setup lang="ts">
import {useSiteInjection} from '~/composables/useSiteInjection';

useSiteInjection();

const { activeSite } = useMultisite();

const enableTransitions = computed(() => {
    const enabledGroups = ['patients'];

    if (activeSite.value?.group && enabledGroups.includes(activeSite.value.group)) {
        return true;
    }
});
</script>

<style>
.enable-transitions.page-enter-active,
.enable-transitions.page-leave-active {
    transition: all var(--transition-timing-medium);
}

.enable-transitions.page-enter-from,
.enable-transitions.page-leave-to {
    opacity: 0;
    transform: translateY(1rem);
}
</style>