import type {SiteConfig, SiteMap} from '~/interfaces/multisite';

import {
    PatientsConfig,
    PatientsConfigNl,
    PatientsConfigEs,
    PatientsConfigDe,
    PatientsConfigFr,
    PatientsConfigAr,
    PatientsConfigEl,
    PatientsConfigPt,
    PatientsConfigSl,
    PatientsConfigTr,
    PatientsConfigCn,
    PatientsConfigIt,
    PatientsConfigLv,
    PatientsConfigFi,
    PatientsConfigCz,
    PatientsConfigSk,
    PatientsConfigPl,
    PatientsConfigHr,
    PatientsConfigBg,
    PatientsConfigAl,
    PatientsConfigHu,
    PatientsConfigEe
} from './sitemap.config.patients';

export const fallbackImage = 'https://d56bochluxqnz.cloudfront.net/media/_1200x630_crop_center-center_none_ns/Uroweb-EAU-Homepage-Header-2022.png';

const setFallbackImage = (config: SiteConfig) => ({
    ...config,
    seo: {
        ...(config?.seo || {}),
        image: config?.seo?.image || fallbackImage
    }
});

const UrowebConfig = {
    name: 'European Association of Urology',
    handle: 'uroweb',
    group: 'uroweb',
    gtmId: 'GTM-5SH8PRG',
    seo: {
        prepend: '- Uroweb',
        description: 'To raise the level of urological care. The EAU represents the leading authority within Europe on urological practice, research and education.',
        image: fallbackImage
    },
    enable: [
        'guidelines',
        'login',
        'hero-highlight',
        'home-selected-topics',
        'home-flex-sections',
        'header-full-navigation',
        'footer-navigation',
        'footer-socials'
    ],
    tags: [{
        name: 'msvalidate.01',
        content: '971BF398CEC8C0E7054982CFCD5C6B44'
    }]
} as SiteConfig;

const EaunConfig = {
    name: 'European Association of Urology Nurses',
    handle: 'eaun',
    group: 'eaun',
    gtmId: 'GTM-5SH8PRG',
    seo: {
        prepend: '- Eaun',
        description: 'To raise the level of urological care. The EAUN represents the leading authority within Europe on urological practice, research and education for nurses.',
        image: fallbackImage
    },
    enable: [
        'login',
        'hero-highlight',
        'home-selected-topics',
        'home-flex-sections',
        'header-full-navigation',
        'footer-navigation',
        'footer-socials'
    ],
    tags: [{
        name: 'robots',
        content: 'noindex'
    }]
} as SiteConfig;

export const defaults: SiteConfig = UrowebConfig;

const definePatientSite = (config: SiteConfig, locale?: string) => ({
    [`${locale ? `${locale}.` : ''}patients-new.uroweb.org`]: setFallbackImage({...config, type: 'prod'}), // Prod temporary // fixme - when going live
    [`${locale ? `${locale}.` : ''}patients.test`]: setFallbackImage({...config, type: 'dev'}),
    [`${locale ? `${locale}.` : ''}patients.test:3000`]: setFallbackImage({...config, type: 'local'}), //ssr
    [`${locale ? `${locale}.` : ''}uroweb-patients.natives.dev`]: setFallbackImage({...config, type: 'staging'}),
}) as SiteMap;

export const sites: SiteMap = {
    // Local
    'localhost': UrowebConfig,
    'uroweb.org': UrowebConfig,
    'uroweb.test': UrowebConfig,
    'uroweb.natives.dev': UrowebConfig,
    // Nurses
    // 'nurses.uroweb.org': EaunConfig, // Prod
    'nurses-new.uroweb.org': EaunConfig, // Prod temporary // fixme - when going live
    'uroweb-nurses.test': EaunConfig, // Local
    'uroweb-nurses.test:3000': EaunConfig, // Local server side
    'uroweb-nurses.natives.dev': EaunConfig, // staging

    // Patients
    'patients.uroweb.org': {
        ...PatientsConfig,
        // Temporary hide lang-switch on production
        enable: PatientsConfig.enable.filter(feature => feature !== 'lang-switch'),
        tags: []
    },
    ...definePatientSite(PatientsConfig),
    ...definePatientSite(PatientsConfig, 'en'),
    ...definePatientSite(PatientsConfigNl, PatientsConfigNl.locale),
    ...definePatientSite(PatientsConfigEs, PatientsConfigEs.locale),
    ...definePatientSite(PatientsConfigDe, PatientsConfigDe.locale),
    ...definePatientSite(PatientsConfigFr, PatientsConfigFr.locale),
    ...definePatientSite(PatientsConfigAr, PatientsConfigAr.locale),
    ...definePatientSite(PatientsConfigEl, PatientsConfigEl.locale),
    ...definePatientSite(PatientsConfigPt, PatientsConfigPt.locale),
    ...definePatientSite(PatientsConfigSl, PatientsConfigSl.locale),
    ...definePatientSite(PatientsConfigTr, PatientsConfigTr.locale),
    ...definePatientSite(PatientsConfigCn, PatientsConfigCn.locale),
    ...definePatientSite(PatientsConfigIt, PatientsConfigIt.locale),
    ...definePatientSite(PatientsConfigLv, PatientsConfigLv.locale),
    ...definePatientSite(PatientsConfigFi, PatientsConfigFi.locale),
    ...definePatientSite(PatientsConfigCz, PatientsConfigCz.locale),
    ...definePatientSite(PatientsConfigSk, PatientsConfigSk.locale),
    ...definePatientSite(PatientsConfigPl, PatientsConfigPl.locale),
    ...definePatientSite(PatientsConfigHr, PatientsConfigHr.locale),
    ...definePatientSite(PatientsConfigBg, PatientsConfigBg.locale),
    ...definePatientSite(PatientsConfigAl, PatientsConfigAl.locale),
    ...definePatientSite(PatientsConfigHu, PatientsConfigHu.locale),
    ...definePatientSite(PatientsConfigEe, PatientsConfigEe.locale),
};
