import revive_payload_client_tfiTJQFezM7oA5J0EExEZpW_5T6sL9nGpwUFTtUYgEs from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_w_0yXkw6nKqlR4GiIktkma1XlgiWgUfy5vclUHswYFw from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rHUhXI7C3QgcauAUwMp0CLh3Qrp_cLx8e1Zk7owUxH8 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-site-config@3.1.7_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client__mkkKx9Q_UQl4OxyqNBZHuMiZsSN6TuTaMoLO_IZty0 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_SqWr3Vh6V4fRwpiM9_WJLoaA2ngbIvI1kzoaidcdUXw from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vUTLBb44SFkcJHXJqoJG19DCDGOaCX87_HJioUnuHtc from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_NYGAJlVNlj4Ii6YcswB56C8pHeZYeh1uXx1umx_kjsU from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/builds/uroweb/uroweb-frontend/.nuxt/components.plugin.mjs";
import prefetch_client__NDhDOlNDne0_i66TaF9XsKzLIzwh1QfGNfSqRXAi3o from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt@3.16.1_@parcel+watcher@2.5.1_@types+node@20.17.28_db0@0.2.4_eslint@8.57.1_ioredis@5.6.0__e7w2hzzw4a2f3ulhndbc6jmjbm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_nsPXijDOdDI5TFqnxBHeWGPG99ny08YjTUwZxbBzjuw from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.5_next-auth@4.21.1_typescript@5.8.2/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import plugin_FYVYB0qZEFp4NAkRwjQOVQ2_T4K0OVWqoE4fvBeZ1ac from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/@digitalnatives+graphql-client@4.2.0_graphql@16.10.0_magicast@0.3.5_nuxt@3.16.1_@parcel+watch_e6eubt2giim5isfxbe2vkgm7sa/node_modules/@digitalnatives/graphql-client/dist/runtime/plugin.js";
import plugin_gq5Ozi3x2FNe8_c6XXDfszyRpAemu2EmaiJFXdk_xew from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0 from "/builds/uroweb/uroweb-frontend/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI from "/builds/uroweb/uroweb-frontend/plugins/globals.ts";
import gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI from "/builds/uroweb/uroweb-frontend/plugins/gtm.ts";
export default [
  revive_payload_client_tfiTJQFezM7oA5J0EExEZpW_5T6sL9nGpwUFTtUYgEs,
  unhead_w_0yXkw6nKqlR4GiIktkma1XlgiWgUfy5vclUHswYFw,
  router_rHUhXI7C3QgcauAUwMp0CLh3Qrp_cLx8e1Zk7owUxH8,
  _0_siteConfig_0KkU7ALq0s1Wln6RC4sEfQzUt6VqTtLPLbYGlQY8Z5A,
  payload_client__mkkKx9Q_UQl4OxyqNBZHuMiZsSN6TuTaMoLO_IZty0,
  navigation_repaint_client_SqWr3Vh6V4fRwpiM9_WJLoaA2ngbIvI1kzoaidcdUXw,
  check_outdated_build_client_vUTLBb44SFkcJHXJqoJG19DCDGOaCX87_HJioUnuHtc,
  chunk_reload_client_NYGAJlVNlj4Ii6YcswB56C8pHeZYeh1uXx1umx_kjsU,
  plugin_vue3_hIN3oJWJ299lZsErxZ6rWmQqYGOFtwVDuRwkyGvCZ8M,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client__NDhDOlNDne0_i66TaF9XsKzLIzwh1QfGNfSqRXAi3o,
  plugin_nsPXijDOdDI5TFqnxBHeWGPG99ny08YjTUwZxbBzjuw,
  plugin_FYVYB0qZEFp4NAkRwjQOVQ2_T4K0OVWqoE4fvBeZ1ac,
  plugin_gq5Ozi3x2FNe8_c6XXDfszyRpAemu2EmaiJFXdk_xew,
  nuxt_plugin_aCXRZlviU_Ryj3EH9LxGiqvOr1f5WNjNa0gU9TSW5P0,
  globals_l0LLwvMn5OXIJiW8MvIXaLXJNj9B5qMK_SI88d520HI,
  gtm_w1mUd627MTflyHOzfKw9s7kvhuSkBafhA0pnEHlbGcI
]