
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexzWRE3HPxCRuXwvsnsiSzKKPoiVZ_NPeh_bhIzmEhnSoMeta } from "/builds/uroweb/uroweb-frontend/pages/index.vue?macro=true";
import { default as searchECLed_3aTMt0n_I7w3n63xRS41_EATl4MLCko_jnfoIMeta } from "/builds/uroweb/uroweb-frontend/pages/search.vue?macro=true";
import { default as contactlFt41a5QjBr_GxW5dulZpYip5BPDAMkj7tOD7fUmCd0Meta } from "/builds/uroweb/uroweb-frontend/pages/contact.vue?macro=true";
import { default as profiler2j1YuBU3MAjUJAAokjm1DNBqL7miQQeNVKFapVog8wMeta } from "/builds/uroweb/uroweb-frontend/pages/profile.vue?macro=true";
import { default as _91_46_46_46slug_93ZyR7xQyI45T9eqMS4Wz1PsLzMpFIk6zj47DS2718SKgMeta } from "/builds/uroweb/uroweb-frontend/pages/[...slug].vue?macro=true";
import { default as indexO7e9pTLoMd7Rlemb12SXtDQ1Qnc6Kj3Uj610eWe3ZmUMeta } from "/builds/uroweb/uroweb-frontend/pages/news/index.vue?macro=true";
import { default as _91_46_46_46slug_93meiJOjBzPH_3VnP8eGhilW_KBGOe_PHFzCZ7s6h_IS8Meta } from "/builds/uroweb/uroweb-frontend/pages/news/[...slug].vue?macro=true";
import { default as _91slug_93d8H5olXoEQsqVkdWEe3y_45CNMfKzIc1B1o0VQfPoNfLcMeta } from "/builds/uroweb/uroweb-frontend/pages/offices/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93qInYNH7GB6UI36gGnTR3TzubMTlEdJAx9W5AcMLmBKMMeta } from "/builds/uroweb/uroweb-frontend/pages/tags/[...slug].vue?macro=true";
import { default as indexEmkBDWoXv8Vl3d_CBE5qTYxtXutGmFcwJQ8FtigNGKwMeta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/index.vue?macro=true";
import { default as _91_46_46_46slug_93HbzFY9i1PeU_vGBj4I1PoGWOzMXKvIXj06V8_3Ij8IoMeta } from "/builds/uroweb/uroweb-frontend/pages/topics/[...slug].vue?macro=true";
import { default as indexcksRKrwZErrQWICdVsXsmTFhzQh9DXL9txWlh7NyP64Meta } from "/builds/uroweb/uroweb-frontend/pages/chat/[botId]/index.vue?macro=true";
import { default as _91_46_46_46slug_93r95_455hDgn_45RhZZAuoxQKNVFEXyveIdBbPJUrVIRPG_sMeta } from "/builds/uroweb/uroweb-frontend/pages/sections/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93ImQN2mJPIk3uh6_45tSUeo5R0l6b1nyFOsNcFQuIZjhnYMeta } from "/builds/uroweb/uroweb-frontend/pages/condition/[...slug].vue?macro=true";
import { default as endqmz_fP7_9cZ0IJW2Kxs3KuHm9eQs6vLu9qTyU1p6R48Meta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/end.vue?macro=true";
import { default as indexgpVbYtWFZSwSOr2_45I0VlpU7bSK69rr4B5RILcVYPUiAMeta } from "/builds/uroweb/uroweb-frontend/pages/press-releases/index.vue?macro=true";
import { default as indexi470IidFuGnhOAnXfp_45xsNY_3qHd5n8fU9b7HlRMhZYMeta } from "/builds/uroweb/uroweb-frontend/pages/undergraduates/index.vue?macro=true";
import { default as indexViCFwgY3YDa5FSt_euZpfxt56Vz25qcAqmGlx8nWBREMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/index.vue?macro=true";
import { default as eventsGA9Bp0M2rQWsifJew9UM5gGUOhY5OOU3dy_45z_0gcY_45sMeta } from "/builds/uroweb/uroweb-frontend/pages/education-events/events.vue?macro=true";
import { default as _91_46_46_46slug_93GMVccz0Ru9JunBLcOkkgsklsceATrgTEgggrfZdIqEgMeta } from "/builds/uroweb/uroweb-frontend/pages/all-conditions/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93XIwFnSBUgoOjKUwtJX6MMMhiQd2TNwLnLoEew4VgBz4Meta } from "/builds/uroweb/uroweb-frontend/pages/all-situations/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93gZMEyCwcrsqhPA3MQdLXFtjqkU9loJMpLAtzmWf4QlIMeta } from "/builds/uroweb/uroweb-frontend/pages/all-treatments/[...slug].vue?macro=true";
import { default as indexZw869et83KzkG4Q_45_45vyEHHfw_tbUgkcBYD0Z_ZGXOz8Meta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/archive/index.vue?macro=true";
import { default as _91_46_46_46slug_937zpyEfXH1aFVa7bw3TMhcqUoyudkN_45PwQyRfuF3nftoMeta } from "/builds/uroweb/uroweb-frontend/pages/press-releases/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93lYlFn0_QlsKUDZxuknayABFIO7xlyBXgv4PJFs351FsMeta } from "/builds/uroweb/uroweb-frontend/pages/undergraduates/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93tmYw7GOOkE0Xz_45HkSXlKFM86y2OjIw6HcbvY75jUOZYMeta } from "/builds/uroweb/uroweb-frontend/pages/education-events/[...slug].vue?macro=true";
import { default as educationSXxcxUK4tJYHw9mGOleKhMsd1jrhA66BSqaBeot170YMeta } from "/builds/uroweb/uroweb-frontend/pages/education-events/education.vue?macro=true";
import { default as _91_46_46_46slug_93x1ux2pCTqKnWuHUCRzfaO7kK8hzyaRlcIC0aRp35aPoMeta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/archive/[...slug].vue?macro=true";
import { default as index_45lWL0KNZrUn1L7aAeFzTkCyezSRFszwktfdsf_45jUsFoMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/index.vue?macro=true";
import { default as indexrqUTnPbCHCZiAO8fFAakv4FiOQfJZTxNEwr7w6CgRJUMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/[item]/index.vue?macro=true";
import { default as chapterzD0okdr4zgcTWgztEyCsreEYQ8lwyglObcC8lCNGZnEMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/chapter.vue?macro=true";
import { default as summary_45by_45year4lovbPxNExHakN986L5z_01TZtzOaug5lSUnwzX1ytoMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-by-year.vue?macro=true";
import { default as summary_45of_45changes7BH_AdtCtsv_45_isVOn_45jTFv1lsabVOydHu_45djah_45K1QMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-of-changes.vue?macro=true";
import { default as publications_45appendicesX4uyvHlQwNO_bNXcchaG310V7s_454KUSxyHhkKdXGE7QMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/publications-appendices.vue?macro=true";
import { default as panelD_45anRTCVLyshBP_45wtrs8Nhio08AI1A9UiXKUL9ShC_QMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/panel.vue?macro=true";
import { default as related_45content0Eg_45BFCMyy1yikIo6SUWa_4512Qo9RjBcoPJRnPKsm5VQMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/related-content.vue?macro=true";
import { default as detailr7ELVx8OjNRFkwGU6joXd3cz_45WIWHoooS9x8UPdASb4Meta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/detail.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/search.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/contact.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profiler2j1YuBU3MAjUJAAokjm1DNBqL7miQQeNVKFapVog8wMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/profile.vue")
  },
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/news/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/news/[...slug].vue")
  },
  {
    name: "offices-slug",
    path: "/offices/:slug()",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/offices/[slug].vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/tags/[...slug].vue")
  },
  {
    name: "guidelines",
    path: "/guidelines",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/index.vue")
  },
  {
    name: "topics-slug",
    path: "/topics/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/topics/[...slug].vue")
  },
  {
    name: "chat-botId",
    path: "/chat/:botId()",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/chat/[botId]/index.vue")
  },
  {
    name: "sections-slug",
    path: "/sections/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/sections/[...slug].vue")
  },
  {
    name: "condition-slug",
    path: "/condition/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/condition/[...slug].vue")
  },
  {
    name: "module-module-end",
    path: "/module/:module()/end",
    meta: endqmz_fP7_9cZ0IJW2Kxs3KuHm9eQs6vLu9qTyU1p6R48Meta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/end.vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/press-releases/index.vue")
  },
  {
    name: "undergraduates",
    path: "/undergraduates",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/undergraduates/index.vue")
  },
  {
    name: "module-module",
    path: "/module/:module()",
    meta: indexViCFwgY3YDa5FSt_euZpfxt56Vz25qcAqmGlx8nWBREMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/index.vue")
  },
  {
    name: "education-events-events",
    path: "/education-events/events",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/events.vue")
  },
  {
    name: "all-conditions-slug",
    path: "/all-conditions/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-conditions/[...slug].vue")
  },
  {
    name: "all-situations-slug",
    path: "/all-situations/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-situations/[...slug].vue")
  },
  {
    name: "all-treatments-slug",
    path: "/all-treatments/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-treatments/[...slug].vue")
  },
  {
    name: "guidelines-archive",
    path: "/guidelines/archive",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/archive/index.vue")
  },
  {
    name: "press-releases-slug",
    path: "/press-releases/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/press-releases/[...slug].vue")
  },
  {
    name: "undergraduates-slug",
    path: "/undergraduates/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/undergraduates/[...slug].vue")
  },
  {
    name: "education-events-slug",
    path: "/education-events/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/[...slug].vue")
  },
  {
    name: "education-events-education",
    path: "/education-events/education",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/education.vue")
  },
  {
    name: "guidelines-archive-slug",
    path: "/guidelines/archive/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/archive/[...slug].vue")
  },
  {
    name: "module-module-chapter",
    path: "/module/:module()/:chapter()",
    meta: index_45lWL0KNZrUn1L7aAeFzTkCyezSRFszwktfdsf_45jUsFoMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/index.vue")
  },
  {
    name: "module-module-chapter-item",
    path: "/module/:module()/:chapter()/:item()",
    meta: indexrqUTnPbCHCZiAO8fFAakv4FiOQfJZTxNEwr7w6CgRJUMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/[item]/index.vue")
  },
  {
    name: "guideline-slug",
    path: "/guidelines/:slug",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/detail.vue"),
    children: [
  {
    name: "guideline-chapterSlug",
    path: "chapter/:chapterSlug",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/chapter.vue")
  },
  {
    name: "guideline-summary",
    path: "summary-of-changes",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-of-changes.vue"),
    children: [
  {
    name: "guideline-summaryByYear",
    path: ":year",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-by-year.vue")
  }
]
  },
  {
    name: "guideline-publications-appendices",
    path: "publications-appendices",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/publications-appendices.vue")
  },
  {
    name: "guideline-panel",
    path: "panel",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/panel.vue")
  },
  {
    name: "related-content",
    path: "related-content",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/related-content.vue")
  }
]
  },
  {
    name: "offices-child-slug",
    path: "/offices/:slug/*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  },
  {
    name: "sections-child-slug",
    path: "/sections/:slug/*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  }
]